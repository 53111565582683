import { FlyoutType, openFlyout } from '@paid-ui/blocks/global-flyout';
import {
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
  DrawerTrigger,
} from '@paid-ui/components/drawer';
import { Logo } from '@paid-ui/components/logo';
import { Separator } from '@paid-ui/components/separator';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { DashboardIcon } from '@paid-ui/icons/dashboard';
import { EmailIcon } from '@paid-ui/icons/email';
import { MenuIcon } from '@paid-ui/icons/menu';
import { userManager } from '@paid-ui/models/user';
import { useSignOut } from '@paid-ui/mutations/sign-out';
import { useSnapshot } from 'valtio/react';

import { NavbarLink } from '../navbar-link';
import { UserAvatar } from '../user-avatar';

export const HamburgerMenu: React.FC = () => {
  const { profile } = useSnapshot(userManager);
  const { mutate, isLoading: isPending } = useSignOut();
  const userName = [profile?.firstName, profile?.lastName].filter(Boolean).join(' ');
  const businessName = profile?.business?.displayName;

  const handleSignOut = () => {
    mutate();
  };

  return (
    <DrawerRoot>
      <DrawerTrigger className="inline-flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center bg-transparent text-white/60 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white/80 sm:hidden">
        <MenuIcon className="h-6 w-6" />
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerContent
          side="left"
          className="right-20 w-auto bg-black text-white landscape:right-[16.8%]"
        >
          <div className="flex h-full w-full flex-col overflow-y-auto overflow-x-hidden p-4">
            <header className="px-5 py-10">
              <Logo height={30} />
            </header>
            <nav className="flex flex-col gap-y-3.5">
              <div>
                <DrawerClose>
                  <NavbarLink mobile href="/" className="py-1">
                    <DashboardIcon className="h-8 w-8 shrink-0 p-1" />
                    <div>Dashboard</div>
                  </NavbarLink>
                </DrawerClose>
                <DrawerClose>
                  <NavbarLink mobile className="ml-11">
                    Claims
                  </NavbarLink>
                </DrawerClose>
                <DrawerClose>
                  <NavbarLink mobile href="/account" className="ml-11">
                    Account
                  </NavbarLink>
                </DrawerClose>
              </div>
              <Separator className="bg-grey-tertiary" />
              <div>
                <div className="mb-2.5 flex items-center gap-x-3 px-3">
                  <UserAvatar className="shrink-0" />
                  <div className="flex flex-1 flex-col">
                    <span className="text-base font-semibold empty:hidden">{userName}</span>
                    <span className="text-sm font-medium empty:hidden">{businessName}</span>
                  </div>
                </div>
                <DrawerClose>
                  <NavbarLink mobile href="/settings" className="ml-11">
                    Profile and settings
                  </NavbarLink>
                </DrawerClose>
                <DrawerClose>
                  <UnstyledButton
                    onClick={handleSignOut}
                    disabled={isPending}
                    className="ml-11 block cursor-pointer px-3 py-2.5 text-left text-base font-semibold text-white"
                  >
                    Sign out
                  </UnstyledButton>
                </DrawerClose>
              </div>
              <Separator className="bg-grey-tertiary" />
              <div>
                <UnstyledButton
                  className="flex items-center gap-x-3 px-3 text-white"
                  onClick={() => {
                    openFlyout(FlyoutType.HELP_CENTER, {
                      className: 'w-[752px]',
                      showCloseIcon: true,
                      closeOnClickOutside: true,
                    });
                  }}
                >
                  <EmailIcon className="h-8 w-8 shrink-0 p-1 text-white" />
                  <span className="flex-1 text-base font-semibold">Contact support</span>
                </UnstyledButton>
              </div>
            </nav>
          </div>
        </DrawerContent>
      </DrawerPortal>
    </DrawerRoot>
  );
};
