import { resetContract } from '@paid-ui/models/contract';
import { resetPayment } from '@paid-ui/models/payment';
import { clearPreSignedUrls } from '@paid-ui/models/pre-signed-url';
import { resetProject } from '@paid-ui/models/project';
import { resetTransactionHistory } from '@paid-ui/models/transaction-history';
import { reset as clearProfile } from '@paid-ui/models/user';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';

export const useSignOut = () => {
  const toast = useToast();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['SignOut'],
    mutationFn: async () => {
      const access_token = sessionStorage.getItem('access_token');
      if (!access_token) return;
      await request.delete('/auth/signout', {
        data: {
          access_token,
        },
      });
    },
    onSuccess() {
      clearProfile();
      client.clear();
      sessionStorage.clear();
      Router.replace('/signin');
      toast.success('Sign out successful.');
      // Reset all global models
      resetPayment();
      resetContract();
      resetProject();
      resetTransactionHistory();
      clearPreSignedUrls();
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to sign out.');
    },
  });
};
